import React from "react"
import Link from "gatsby-link"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import TruncateMarkup from "react-truncate-markup"

import Card from "react-bootstrap/Card"

import "./newsletter-preview.scss"

const NewsletterPreview = ({ post, ...otherProps }) => {
  return (
    <Link to={`/newsletter/${post.slug}`} className="card" className="wrapper">
      {post.heroImage && post.heroImage.sizes && (
        // <Img
        //   className="card-img-top"
        //   className="img"
        //   title={post.title}
        //   sizes={post.heroImage.sizes}
        // />
        <GatsbyImage
          image={post.heroImage.gatsbyImageData}
          className="card-img-top img"
           title={post.title}
        />
      )}
      <Card.Body className="body">
        <Card.Title className="title">
          <TruncateMarkup lines={2}>
            <h5>{post.title}</h5>
          </TruncateMarkup>
        </Card.Title>
        <p className="subtitle">
          <TruncateMarkup lines={2}>
            <span>{post.subtitle}</span>
          </TruncateMarkup>
        </p>
      </Card.Body>
    </Link>
  )
}

export default NewsletterPreview

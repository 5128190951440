import React, { useState } from "react"
import Link from "gatsby-link"
// import Img from "gatsby-image"
import { graphql } from "gatsby"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

import WhitePaperForm from "../components/white-paper-form"

import "./newsletter.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsletterPreview from "../components/newsletter-preview.js"

const ColumnProps = {
  xs: 12,
  sm: 6,
  md: 4,
  // lg: 4
}

const Newsletter = ({ data }) => {
  const allPosts =
    (data &&
      data.allContentfulNewsletter &&
      data.allContentfulNewsletter.edges) ||
    []

  return (
    <Layout>
      <SEO title="Newsletter" />
      <div className="wrapper">
        <br />
        <div className="post-wrapper">
          <div className="grid-posts">
            {allPosts.map((post, idx) => (
              <div>
                <NewsletterPreview post={post.node} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Newsletter

export const pageQuery = graphql`
  query MyQuery {
    allContentfulNewsletter(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...NewsletterPreviewFields
        }
      }
    }
  }
`

export const NewsletterPreviewFields = graphql`
  fragment NewsletterPreviewFields on ContentfulNewsletter {
    title
    subtitle
    slug
    publishDate(formatString: "MMMM D, YYYY")
    tags
    type
    heroImage {
      gatsbyImageData(layout: FIXED)
    }
  }
`
